<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Services</h2>
    </template>
    <template #action-bar></template>
    <template #page-content>
      <b-loading
        :is-full-page="true"
        v-model="loading"
        :can-cancel="true"
      ></b-loading>
      <div class="columns is-multiline" v-if="billing">
        <div class="column is-6 box is-offset-one-quarter">
          <h4>Service Cost</h4>
          <div class="columns">
            <div class="column is-8">
              <div class="has-text-weight-bold">Product</div>
            </div>
            <div class="column is-4">
              <div class="has-text-weight-bold">Total</div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8" style="padding-top: 0">
              <div>{{ billing.package.packageName }}</div>
            </div>
            <div class="column is-4" style="padding-top: 0">
              <div style="font-weight: bold">
                Rp {{ billing.package.price | currency }}
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8" style="padding-top: 0">
              <div>Prorate</div>
            </div>
            <div class="column is-4" style="padding-top: 0">
              <div style="font-weight: bold">
                Rp -{{ billing.prorate | currency }}
              </div>
            </div>
          </div>
          <div class="columns" v-if="selectedVoucher">
            <div class="column is-8" style="padding-top: 0">
              <div>
                Voucher ({{ selectedVoucher.name }})
                <span
                  style="font-weight: 100px; color: #999999; cursor: pointer"
                  @click="resetVoucher"
                >
                  X
                </span>
              </div>
            </div>
            <div class="column is-4" style="padding-top: 0">
              <div style="font-weight: bold">
                Rp -{{ countVoucher | currency }}
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12" style="padding: 5px">
              <hr />
            </div>
          </div>
          <div class="columns">
            <div class="column is-8" style="padding-top: 0">
              <div>Grand Total</div>
            </div>
            <div class="column is-4" style="padding-top: 0">
              <div style="font-weight: bold">
                Rp. {{ billing.price | currency }}
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6 box is-offset-one-quarter">
          <h4>Select Payment</h4>
          <div>
            <b-radio v-model="paymentMethod" native-value="Transfer BCA">
              Transfer BCA (Manual)
            </b-radio>
          </div>
          <div>
            <b-radio v-model="paymentMethod" native-value="bca_va">
              BCA Virtual Account
            </b-radio>
          </div>
          <div>
            <b-radio v-model="paymentMethod" native-value="credit_card">
              Credit Card
            </b-radio>
          </div>
          <div>
            <b-radio v-model="paymentMethod" native-value="e_wallet">
              E-Wallet
            </b-radio>
          </div>
          <div class="columns">
            <div class="column is-5">
              <b-input
                placeholder="Voucher Code"
                @input="toUpperCase"
                v-model="voucher"
                :disabled="selectedVoucher != null"
              ></b-input>
            </div>
            <div class="column is-7">
              <b-button
                @click="checkVoucher"
                :disabled="selectedVoucher != null"
              >
                Submit
              </b-button>
              <b-icon
                icon="delete"
                @click.native="resetVoucher"
                style="padding-top: 12px; margin-left: 12px"
              ></b-icon>
            </div>
          </div>
        </div>
        <div class="column is-6 is-offset-one-quarter" style="padding: 0">
          <b-button expanded @click="pay">Continue</b-button>
        </div>
      </div>
    </template>
  </r-page>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      paymentMethod: 'Transfer BCA',
      billing: null,
      voucher: null,
      selectedVoucher: null,
      loading: false,
    }
  },
  mounted() {
    let midtrans = document.createElement('script')
    midtrans.setAttribute(
      'src',
      'https://app.sandbox.midtrans.com/snap/snap.js'
    )
    midtrans.setAttribute(
      'data-client-key',
      process.env.VUE_APP_MIDTRANS_CLIENT_KEY
    )
    midtrans.async = true
    document.head.appendChild(midtrans)
  },
  created() {
    this.fetchDataBilling(this.$route.params.id)
  },
  methods: {
    toUpperCase() {
      this.voucher = this.voucher.toUpperCase()
    },
    fetchDataBilling(id) {
      this.$store.dispatch('payments/getBilling2', id).then((data) => {
        this.billing = data
        this.selectedVoucher = this.billing.voucherId
          ? this.billing.voucher
          : null
        if (
          this.billing.price == 0 &&
          this.selectedVoucher.status == 'nominal'
        ) {
          let value =
            this.selectedVoucher.value -
            (this.selectedVoucher.value -
              (this.billing.package.price + this.billing.prorate))
          this.selectedVoucher.value = value
          this.voucher = this.selectedVoucher.voucherCode
          this.billing.price += -this.billing.price
        }
        console.log(this.billing)
      })
    },
    async pay() {
      this.billing.paymentMethod = this.paymentMethod
      if (this.billing.price == 0) {
        this.billing.status = 'paid'
        this.billing.activeDate = moment().format('YYYY-MM-DD')
        this.billing.actualPayDate = moment().format('YYYY-MM-DD')
        this.$store
          .dispatch('payments/createPackage', this.billing)
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Pembayaran anda telah selesai! Package telah aktif.',
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
            this.$router.push({
              name: 'dashboard-package',
              params: {
                id: this.billing.id,
              },
            })
          })
      } else {
        if (this.paymentMethod != 'Transfer BCA') {
          this.loading = true
          await this.$store
            .dispatch('payments/getSnapToken', {
              id: this.billing.id,
              paymentMethod: this.paymentMethod,
            })
            .then((data) => {
              this.loading = false
              this.billing.snapToken = data.token
            })
        }
        this.$store
          .dispatch('payments/createPackage', this.billing)
          .then(() => {
            if (this.paymentMethod != 'Transfer BCA') {
              window.snap.pay(this.billing.snapToken)
            } else {
              this.$router.push({
                name: 'payment-billing-package',
                params: {
                  id: this.billing.id,
                },
              })
            }
          })
      }
    },
    resetVoucher() {
      if (this.selectedVoucher) {
        this.voucher = null
        this.selectedVoucher = null
        this.billing.voucherId = null
        this.billing.price = this.billing.package.price - this.billing.prorate
      }
    },
    checkVoucher() {
      if (this.voucher) {
        this.$store.dispatch('voucher/checkVoucher', this.voucher).then(
          (response) => {
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: `Congratulation voucher ${response.data.name} is valid!`,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
            this.resetVoucher()
            console.log(response.data)
            this.selectedVoucher = response.data
            let diskon = 0
            if (this.selectedVoucher.status == 'nominal') {
              diskon = this.selectedVoucher.value
            } else if (this.selectedVoucher.status == 'percentage') {
              diskon = (this.selectedVoucher.value / 100) * this.billing.price
            }

            this.billing.price -= Math.round(diskon)
            if (this.billing.price < 0) {
              this.selectedVoucher.value -= -this.billing.price
              this.billing.price += -this.billing.price
            }
            this.billing.voucherId = this.selectedVoucher.id

            // this.voucher = null
          },
          () => {
            this.$swal({
              icon: 'error',
              title: 'Failed!',
              text: 'Voucher is not valid!',
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'button is-danger',
              },
            })
          }
        )
      }
    },
  },
  computed: {
    countVoucher() {
      if (this.selectedVoucher.status == 'percentage') {
        let diskon =
          (this.selectedVoucher.value / 100) *
          (this.billing.package.price - this.billing.prorate)

        return Number(Math.round(diskon))
      }

      return Number(this.selectedVoucher.value)
    },
  },
  filters: {
    currency(num) {
      if (!num) {
        return '0.00'
      }
      // const number = (num / 1).toFixed(2).replace('.', '.')
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>

<style scoped>
.box {
  border: 1px solid #f9d6d6;
  padding: 20px;
}
</style>
